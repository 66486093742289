<template>
    <div class="board__input">

        <h2 class="tit tit--lg text-center line-height-12 mb-20 mb-lg-40">
            묻고 답하기
        </h2>

        <p class="page-text page-text--sm mb-8 mb-lg-16 primary--text">
            *입력시 유의사항 안내 : 익명 보장을 위해 회원이시더라도 작성자는 저장되지 않으며, 작성시 입력한 비밀번호를 잊으시면 질의하신 내용 및 답변은 확인이 불가합니다.
        </p>

        <div class="mb-8 mb-lg-20 text-right">
            <p class="page-text page-text--sm grey-6--text line-height-1">
                <span class="primary--text">*</span> 필수입력 항목입니다.
            </p>
        </div>
        <div class="form--primary">
            <form-row tit="제목" pointer>
                <v-text-field v-model="question.subject" placeholder="제목을 입력하세요." outlined hide-details />
            </form-row>
            <form-row tit="비밀번호" pointer>
                <v-row class="row--x-small" align="center">
                    <v-col cols="12" sm="auto">
                        <v-checkbox v-model="question.isPassword" label="비밀글" hide-details class="v-cehckbox--sm mr-2" />
                    </v-col>
                    <v-col>
                        <v-text-field v-model="question.password" placeholder="비밀번호를 입력하세요." outlined hide-details/>
                    </v-col>
                </v-row>
            </form-row>
            <form-row tit="질문 키워드" pointer>
                <v-select v-model="question.keyword" :items="keywordItems" placeholder="--선택--" outlined hide-details />
            </form-row>
            <form-row tit="질문 내용" pointer>
                <v-text-field v-model="question.content" placeholder="질문 내용을 입력하세요." outlined hide-details />
            </form-row>
        </div>

        <div class="form__agreement">
            <v-checkbox v-model="agree" label="질문 2차 사용 동의서" hide-details />
            <button onclick="window.open('/use', '', 'width=483,height=700');" class="form__agreement__btn">전문보기</button>
        </div>
        
        <div class="v-btn--group">
            <v-btn @click="$router.go(-1)" outlined color="primary" class="min-w-120px min-w-lg-160px">
                리스트
            </v-btn>
            <v-btn color="primary" class="min-w-120px min-w-lg-160px" @click="save">
                질의하기
            </v-btn>
        </div>
    </div>
</template>

<script>
import api from "@/api"
import CryptoAES from '@/plugins/crypto-aes';
import FormRow from "@/components/dumb/form-row.vue";

export default {
    props: {
        _question: { type: String, default: null }
    },
    components: {
        FormRow
    },

    data() {
        return {
            keywordItems: [
                { text: "--선택--", value: null },
                { text: "마약", value: "마약" },
                { text: "성", value: "성" },
                { text: "도박", value: "도박" },
                { text: "인터넷", value: "인터넷" },
                { text: "알코올", value: "알코올" },
            ],
            agree: false,
            passwordCheck: false,
            question: {
                subject: null,
                isPassword: false,
                password: null,
                keyword: null,
                content: null,
            }
        };
    },
    async mounted() {
        if(this._question) {
            await this.init()
        }
    },
    methods: {
        async init() {
            let { question } = await api.v1.center.questions.get({ _id: this._question, code: "edit" })
            this.question = question
        },
        validate() {
            try {
                if (!this.question.subject) throw new Error("제목을 입력해주세요");
                if (!this.question.password) throw new Error("비밀번호를 입력해주세요");
                if (!this.question.keyword) throw new Error("키워드를 선택해주세요");
                if (!this.question.content) throw new Error("질문 내용을 입력해주세요");
                if (!this.agree) throw new Error("질문 2차 사용 동의서에 동의해주세요")

                return true;
            } catch (error) {
                alert(error.message);
                return false;
            }
        },
        async save() {
            if(this.validate()) {
                let { question } = this._question ? await api.v1.center.questions.put({...this.question, password: CryptoAES.encrypt(this.question.password )}) : await api.v1.center.questions.post({...this.question, password: CryptoAES.encrypt(this.question.password )})
                alert("질문이 등록되었습니다")
                this.$router.go(-1)
            }
        }
    }
};
</script>

<style lang="scss" scoped>
</style>