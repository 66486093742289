<template>
    <div class="form-row w-100">
        <v-row align-md="center">
            <v-col cols="12" md="auto" class="mb-n26 mb-md-0">
                <div class="pa-12 py-md-16 px-md-20 w-md-140px">
                    <p class="page-text grey-1--text font-weight-medium">
                        {{tit}} <span class="primary--text" v-if="pointer">*</span>
                    </p>
                </div>
            </v-col>
            <v-col cols="12" md="">
                <div class="pa-12 py-md-16 px-md-20">
                    <slot />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default{
    props : {
        tit : {type :String, default : ""},
        pointer: { type: Boolean, default: false },
    },
	components: {
	},
}
</script>
<style lang="scss" scoped>
.form-row{
    border-bottom: 1px solid #ececec;
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}

</style>